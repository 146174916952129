import { useState } from "react";
import { useEvent } from "./useEvent";
import { isFunction } from "../utils/typeGuards";

function getSearchParam(search: string, param: string) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(param);
}

function setSearchParam(search: string, param: string, value: string) {
    const searchParams = new URLSearchParams(search);
    if (value) searchParams.set(param, value);
    else searchParams.delete(param);
    return searchParams.toString();
}

export type DeserializeFunction<Value> = (value: string | null) => Value;
export type SerializeFunction<Value> = (value: Value) => string;

const defaultDeserialize = <Value>(v: string | null) => v as unknown as Value;
const defaultSerialize = String;

interface UseSearchParamsStateOptions<Value> {
    name: string;
    serialize?: SerializeFunction<Value>;
    deserialize?: DeserializeFunction<Value>;
}

export function useSearchParamsState<Value>({
    name,
    serialize = defaultSerialize,
    deserialize = defaultDeserialize,
}: UseSearchParamsStateOptions<Value>) {
    const [value, setValue] = useState(() => {
        const initialValue = deserialize(
            getSearchParam(window.location.search, name)
        );

        return initialValue;
    });

    const updateValue = useEvent((newValue: React.SetStateAction<Value>) => {
        const search = window.location.search;
        const actualNewValue = isFunction(newValue)
            ? newValue(value)
            : newValue;

        setValue(actualNewValue);

        const newSearch = setSearchParam(
            search,
            name,
            serialize(actualNewValue)
        );

        const searchArg = newSearch
            ? `?${newSearch}`
            : window.location.pathname;

        window.history.pushState(null, "", searchArg);
    });

    return [value, updateValue] as const;
}
