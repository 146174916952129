import { useMemo } from "react";
import { useSearchParamsState } from "./useQueryParamsState";
import { deserializeFilter, serializeFilter } from "../utils/filters";

export const useFilterSearchParamsState = <Item extends { id: number }>(
    name: string,
    items: Item[]
) => {
    const defaultValues = useMemo(() => {
        return items.map((item) => item.id);
    }, [items]);

    const [ids, setIds] = useSearchParamsState({
        name,
        serialize: serializeFilter(items),
        deserialize: deserializeFilter(defaultValues),
    });

    return [ids, setIds] as const;
};
