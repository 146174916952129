import { sortValueType } from "../hooks/useSort";
import { AdType, IAd } from "../models/IAd";
import BaseService from "./base-service";

export interface getCommonStatisticksBody {
    period: {
        from: Date;
        to: Date;
    };
    filter: {
        countryIds: number[];
        productIds: number[];
    };
}

export interface getProjectsStatisticksBody {
    period: {
        from: Date;
        to: Date;
    };
    search: string;
    isActive: boolean;
    filter: {
        countryIds: number[];
        productIds: number[];
    };
}

export interface getExpensesStatisticksBody {
    type: "google" | "yandex" | "total";
    period: {
        from: Date;
        to: Date;
    };
    filter: {
        countryIds: number[];
        productIds: number[];
    };
}

export interface getBalanceStatisticksBody {
    type: AdType;
    search: string;
    period: {
        from: Date;
        to: Date;
    };
    filter: {
        countryIds: number[];
        productIds: number[];
        currencyIds: number[];
    };
    sort: {
        key: string;
        value: sortValueType;
    };
}
export interface getBalanceStatisticksBody {
    type: AdType;
    search: string;
    period: {
        from: Date;
        to: Date;
    };
    filter: {
        countryIds: number[];
        productIds: number[];
        currencyIds: number[];
    };
    sort: {
        key: string;
        value: sortValueType;
    };
}

export interface getCallsStatisticksBody {
    period: {
        from: Date;
        to: Date;
    };
}

class StatisticksService extends BaseService {
    async getCommon(data: getCommonStatisticksBody) {
        return this.api.post("/statisticks/get-common", data);
    }
    async getProjects(data: getProjectsStatisticksBody) {
        return this.api.post("/statisticks/get-projects", data);
    }
    async getExpenses(data: getExpensesStatisticksBody) {
        return this.api.post("/statisticks/get-expense", data);
    }
    async updateBalances(data: IAd) {
        return this.api.put("/ad", data);
    }
    async getBalance(data: getBalanceStatisticksBody) {
        return this.api.post("/statisticks/get-balance", data);
    }
    async getCalls(data: getCallsStatisticksBody) {
        return this.api.post("/statisticks/get-calls", data);
    }
}

export default new StatisticksService();
