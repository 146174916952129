/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAppSelector } from "../../hooks/redux";
import SelectFilter from "./SelectFilter";
import { useFilterSearchParamsState } from "../../hooks/useFilterSearchParamsState";

type ProductFilterProps = {
    setIds: (ids: number[]) => void;
    clearTigger?: boolean;
};

const ProductFilter: React.FC<ProductFilterProps> = ({
    setIds,
    clearTigger,
}) => {
    const products = useAppSelector((state) => state.product.products);

    const [ids, setProductIds] = useFilterSearchParamsState(
        "products",
        products
    );

    useEffect(() => {
        setIds(ids);
    }, [ids]);

    return (
        <SelectFilter
            clearTrigger={clearTigger}
            filterName="Продукт"
            items={products}
            setFilter={setProductIds}
            defaultValues={ids}
        />
    );
};

export default ProductFilter;
