/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAppSelector } from "../../hooks/redux";
import SelectFilter from "./SelectFilter";
import { useFilterSearchParamsState } from "../../hooks/useFilterSearchParamsState";

type CountryFilterProps = {
    setCoutryIds: (value: number[]) => void;
    clearTigger?: boolean;
};

const CountryFilter: React.FC<CountryFilterProps> = ({
    setCoutryIds: setIds,
    clearTigger,
}) => {
    const countries = useAppSelector((state) => state.country.countries);

    const [countryIds, setCountryIds] = useFilterSearchParamsState(
        "countries",
        countries
    );

    useEffect(() => {
        setIds(countryIds);
    }, [countryIds]);

    return (
        <SelectFilter
            clearTrigger={clearTigger}
            filterName="Регион"
            items={countries}
            setFilter={setCountryIds}
            defaultValues={countryIds}
        />
    );
};

export default CountryFilter;
