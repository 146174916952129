/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    BoxProps,
    Button,
    Checkbox,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    Text,
    TextProps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export type SelectFilterItem = {
    id: number;
    name: string;
    props?: BoxProps;
    textProps?: TextProps;
};

type SelectFilterProps = {
    items: SelectFilterItem[];
    setFilter: (value: number[]) => void;
    filterName: string;
    defaultValues?: number[];
    clearTrigger?: boolean;
} & BoxProps;

export const initialId = -2;
export const initialIds = [initialId];
export const isInitial = (ids: number[]) => ids.includes(initialId);

export const allId = -1;

const SelectFilter = (props: SelectFilterProps) => {
    const {
        items,
        filterName,
        setFilter,
        defaultValues,
        clearTrigger,
        ...rest
    } = props;

    const [defaultIds, setDefaultIds] = useState(
        defaultValues || [allId, ...items.map((item) => item.id)]
    );

    const [ids, setIds] = useState(defaultIds);

    const onClick = (id: number) => () => {
        const exist = ids.find((_id) => _id === id);
        const isAll = id === allId;

        if (exist) {
            if (isAll) return setIds([]);

            if ((ids.length = defaultIds.length))
                setIds((prev) => prev.filter((id) => id !== allId));

            return setIds((prev) => prev.filter((_id) => _id !== id));
        }

        if (isAll) return setIds(defaultIds);

        if (ids.length >= defaultIds.length - 2)
            setIds((prev) => [allId, ...prev]);

        return setIds((prev) => [...prev, id]);
    };

    useEffect(() => {
        if (clearTrigger) {
            setIds(defaultIds);
        }
    }, [clearTrigger]);

    useEffect(() => {
        const _defaultValues =
            defaultValues?.length === items.length
                ? [allId, ...defaultValues]
                : defaultValues;
        const values = _defaultValues || [
            allId,
            ...items.map((item) => item.id),
        ];
        setIds(values);
        setDefaultIds([allId, ...items.map((item) => item.id)]);
    }, [items]);

    useEffect(() => {
        setFilter(ids.filter((id) => id !== allId));
    }, [ids, setFilter]);

    return (
        <Box {...rest}>
            <Menu isLazy>
                <MenuButton
                    w="100%"
                    bg={"transparent"}
                    fontWeight={400}
                    borderWidth={1}
                    rounded={2}
                    size="sm"
                    as={Button}
                    _active={{
                        bg: "transparent",
                    }}
                    rightIcon={<FaChevronDown />}
                >
                    {filterName}
                </MenuButton>
                <MenuList maxH={"50vh"} overflowY="auto">
                    <HStack
                        userSelect={"none"}
                        cursor={"pointer"}
                        px={4}
                        py={2}
                        onClick={onClick(-1)}
                        _hover={{ bg: "gray.100" }}
                    >
                        <Checkbox isChecked={!!ids.find((id) => id === -1)} />
                        <Text fontSize={"14px"} color="black">
                            Все
                        </Text>
                    </HStack>
                    {items.map((item) => (
                        <HStack
                            userSelect={"none"}
                            cursor={"pointer"}
                            px={4}
                            py={2}
                            key={item.id}
                            onClick={onClick(item.id)}
                            _hover={{ bg: "gray.100" }}
                            {...item.props}
                        >
                            <Checkbox
                                isChecked={!!ids.find((id) => id === item.id)}
                            />
                            <Text
                                fontSize={"14px"}
                                color="black"
                                {...item.textProps}
                            >
                                {item.name}
                            </Text>
                        </HStack>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default SelectFilter;
