import {
    DeserializeFunction,
    SerializeFunction,
} from "../hooks/useQueryParamsState";

export const getPropForSearchParams = (ids: number[], items: any[]) =>
    ids.length === items.length ? "" : JSON.stringify(ids);

export const deserializeFilter: (
    defaultValues: number[]
) => DeserializeFunction<number[]> = (defaultValues) => (value) => {
    if (!value) return defaultValues;

    try {
        return JSON.parse(value) as number[];
    } catch (e) {
        return defaultValues;
    }
};

export const serializeFilter: <Item>(
    items: Item[]
) => SerializeFunction<number[]> = (items) => (value) =>
    getPropForSearchParams(value, items);
