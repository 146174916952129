import { useCallback, useMemo } from "react";
import { IUser } from "../models/IUser";
import { useAppSelector } from "./redux";

export const ROLES = {
    ADMIN: "ADMIN",
    DEALER: "DEALER",
    MANAGER: "MANAGER",
};

export const userHasRoles = (user: IUser | null, roles: string[]) =>
    user?.roles.find((role) => {
        for (let roleName of roles) {
            if (roleName === role.role) return true;
        }

        return false;
    }) || false;

export const useRoles = () => {
    const { user } = useAppSelector((state) => state.user);

    const roles = useMemo(() => user?.roles || [], [user]);

    const hasRoles = useCallback(
        (roles: string[]) => userHasRoles(user, roles),
        [user]
    );

    return {
        roles,
        hasRoles,
    };
};
